<template>
  <div class="h-auto w-full mt-5 text-left flex flex-col">
    <label
      :for="id"
      v-if="label"
      class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b>
      <span v-if="legend" class="text-gray-500">{{ legend }}</span>
      <span v-if="required" class="text-red-500">*</span></label
    >
    <a-tooltip :title="tooltip">
      <div class="flex gap-8 recovery justify-center">
        <a-input
          v-for="(field, idx) in fields"
          :key="field"
          :ref="idx"
          :type="type"
          data-bs-toggle="tooltip"
          @input="inputData($event, idx)"
          autocomplete="false"
          class="text-code text-blue-700 font-bold text-center border-blue-700"
          :maxLength="1"
        />
      </div>
    </a-tooltip>
    <p v-if="invalid" class="mt-2 text-xs text-red-500 text-center">
      {{ feedback ? feedback : "Este campo es obligatorio" }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    type: { default: "text" },
    label: String,
    value: {
      type: [],
      default: () => {
        return [];
      },
    },
    danger: Boolean,
    required: { type: Boolean, default: true },
    legend: String,
    tooltip: String,
    fields: {
      type: Number,
      default: 4,
    },
    isInvalid: { type: Boolean, default: false },
  },
  data() {
    return {
      invalid: false,
      feedback: "",
      pattern: "",
      cleaning: false,
      code: [],
      values: 1,
    };
  },
  methods: {
    checkInput() {
      if (this.value.length != this.fields) {
        this.invalid = true;
        this.feedback = "El código de verificación es obligatorio";
        return false;
      } else {
        this.invalid = false;
        this.feedback = "";
        return true;
      }
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
    inputData(event, idx) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
      this.code[idx] = event.target.value;
      this.$emit("input", this.code.join(""));
      this.nextValueCode(idx);
    },
    nextValueCode(idx) {
      if (idx < this.fields - 1 && this.values < this.fields) {
        this.$refs[idx + 1][0].$el.disabled = false;
        this.$refs[idx + 1][0].$el.focus();
        this.values++;
      }
    },
    clearInput() {
      this.$emit("input", "");
      this.cleaning = false;
    },
  },
  computed: {
    classObject: function () {
      let classes = ["border-gray-300", "placeholder-gray-400"];
      if (this.danger || this.invalid) {
        classes = ["border-red-500", "placeholder-red-500"];
      }
      return classes;
    },

    valueInput: function () {
      return this.cleaning ? "" : this.value;
    },
  },
  watch: {},
};
</script>
<style>
.recovery > .ant-input {
  font-size: 4em;
  padding: 0.1em;
  height: 1em;
  width: 1em;
}
</style>
